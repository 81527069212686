/* 
   http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

/* 
    HTML5 display-role reset for older browsers 
*/

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}
body {
    line-height: 1;
}
ol,
ul {
    list-style: none;
}
blockquote,
q {
    quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
    content: "";
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}

a {
    cursor: pointer;
}

/* 
    You can add global styles to this file, and also import other style files 
*/

body {
    overflow-x: hidden;
}

*:focus {
    outline: none !important;
    box-shadow: none !important;
}

*::-webkit-scrollbar {
    background-color: #fff;
    width: 12px;
}

*::-webkit-scrollbar-track {
    background-color: var(--primary-50);
}

*::-webkit-scrollbar-thumb {
    background-color: var(--primary-200);
    border-radius: 0px;
}

.bg-rkube-color1-50 {
    background-color: var(--rkube-color1-50) !important;
}
.bg-rkube-color1-100 {
    background-color: var(--rkube-color1-100) !important;
}
.bg-rkube-color1-200 {
    background-color: var(--rkube-color1-200) !important;
}
.bg-rkube-color1-300 {
    background-color: var(--rkube-color1-300) !important;
}
.bg-rkube-color1-400 {
    background-color: var(--rkube-color1-400) !important;
}
.bg-rkube-color1-500 {
    background-color: var(--rkube-color1-500) !important;
}
.bg-rkube-color1-600 {
    background-color: var(--rkube-color1-600) !important;
}
.bg-rkube-color1-700 {
    background-color: var(--rkube-color1-700) !important;
}
.bg-rkube-color1-800 {
    background-color: var(--rkube-color1-800) !important;
}
.bg-rkube-color1-900 {
    background-color: var(--rkube-color1-900) !important;
}

.bg-rkube-color2-50 {
    background-color: var(--rkube-color2-50) !important;
}
.bg-rkube-color2-100 {
    background-color: var(--rkube-color2-100) !important;
}
.bg-rkube-color2-200 {
    background-color: var(--rkube-color2-200) !important;
}
.bg-rkube-color2-300 {
    background-color: var(--rkube-color2-300) !important;
}
.bg-rkube-color2-400 {
    background-color: var(--rkube-color2-400) !important;
}
.bg-rkube-color2-500 {
    background-color: var(--rkube-color2-500) !important;
}
.bg-rkube-color2-600 {
    background-color: var(--rkube-color2-600) !important;
}
.bg-rkube-color2-700 {
    background-color: var(--rkube-color2-700) !important;
}
.bg-rkube-color2-800 {
    background-color: var(--rkube-color2-800) !important;
}
.bg-rkube-color2-900 {
    background-color: var(--rkube-color2-900) !important;
}

.bg-rkube-color3-50 {
    background-color: var(--rkube-color3-50) !important;
}
.bg-rkube-color3-100 {
    background-color: var(--rkube-color3-100) !important;
}
.bg-rkube-color3-200 {
    background-color: var(--rkube-color3-200) !important;
}
.bg-rkube-color3-300 {
    background-color: var(--rkube-color3-300) !important;
}
.bg-rkube-color3-400 {
    background-color: var(--rkube-color3-400) !important;
}
.bg-rkube-color3-500 {
    background-color: var(--rkube-color3-500) !important;
}
.bg-rkube-color3-600 {
    background-color: var(--rkube-color3-600) !important;
}
.bg-rkube-color3-700 {
    background-color: var(--rkube-color3-700) !important;
}
.bg-rkube-color3-800 {
    background-color: var(--rkube-color3-800) !important;
}
.bg-rkube-color3-900 {
    background-color: var(--rkube-color3-900) !important;
}

.bg-rkube-color4-50 {
    background-color: var(--rkube-color4-50) !important;
}
.bg-rkube-color4-100 {
    background-color: var(--rkube-color4-100) !important;
}
.bg-rkube-color4-200 {
    background-color: var(--rkube-color4-200) !important;
}
.bg-rkube-color4-300 {
    background-color: var(--rkube-color4-300) !important;
}
.bg-rkube-color4-400 {
    background-color: var(--rkube-color4-400) !important;
}
.bg-rkube-color4-500 {
    background-color: var(--rkube-color4-500) !important;
}
.bg-rkube-color4-600 {
    background-color: var(--rkube-color4-600) !important;
}
.bg-rkube-color4-700 {
    background-color: var(--rkube-color4-700) !important;
}
.bg-rkube-color4-800 {
    background-color: var(--rkube-color4-800) !important;
}
.bg-rkube-color4-900 {
    background-color: var(--rkube-color4-900) !important;
}

.bg-rkube-color5-50 {
    background-color: var(--rkube-color5-50) !important;
}
.bg-rkube-color5-100 {
    background-color: var(--rkube-color5-100) !important;
}
.bg-rkube-color5-200 {
    background-color: var(--rkube-color5-200) !important;
}
.bg-rkube-color5-300 {
    background-color: var(--rkube-color5-300) !important;
}
.bg-rkube-color5-400 {
    background-color: var(--rkube-color5-400) !important;
}
.bg-rkube-color5-500 {
    background-color: var(--rkube-color5-500) !important;
}
.bg-rkube-color5-600 {
    background-color: var(--rkube-color5-600) !important;
}
.bg-rkube-color5-700 {
    background-color: var(--rkube-color5-700) !important;
}
.bg-rkube-color5-800 {
    background-color: var(--rkube-color5-800) !important;
}
.bg-rkube-color5-900 {
    background-color: var(--rkube-color5-900) !important;
}

.text-rkube-color1-50 {
    color: var(--rkube-color1-50) !important;
}
.text-rkube-color1-100 {
    color: var(--rkube-color1-100) !important;
}
.text-rkube-color1-200 {
    color: var(--rkube-color1-200) !important;
}
.text-rkube-color1-300 {
    color: var(--rkube-color1-300) !important;
}
.text-rkube-color1-400 {
    color: var(--rkube-color1-400) !important;
}
.text-rkube-color1-500 {
    color: var(--rkube-color1-500) !important;
}
.text-rkube-color1-600 {
    color: var(--rkube-color1-600) !important;
}
.text-rkube-color1-700 {
    color: var(--rkube-color1-700) !important;
}
.text-rkube-color1-800 {
    color: var(--rkube-color1-800) !important;
}
.text-rkube-color1-900 {
    color: var(--rkube-color1-900) !important;
}

.text-rkube-color2-50 {
    color: var(--rkube-color2-50) !important;
}
.text-rkube-color2-100 {
    color: var(--rkube-color2-100) !important;
}
.text-rkube-color2-200 {
    color: var(--rkube-color2-200) !important;
}
.text-rkube-color2-300 {
    color: var(--rkube-color2-300) !important;
}
.text-rkube-color2-400 {
    color: var(--rkube-color2-400) !important;
}
.text-rkube-color2-500 {
    color: var(--rkube-color2-500) !important;
}
.text-rkube-color2-600 {
    color: var(--rkube-color2-600) !important;
}
.text-rkube-color2-700 {
    color: var(--rkube-color2-700) !important;
}
.text-rkube-color2-800 {
    color: var(--rkube-color2-800) !important;
}
.text-rkube-color2-900 {
    color: var(--rkube-color2-900) !important;
}

.text-rkube-color3-50 {
    color: var(--rkube-color3-50) !important;
}
.text-rkube-color3-100 {
    color: var(--rkube-color3-100) !important;
}
.text-rkube-color3-200 {
    color: var(--rkube-color3-200) !important;
}
.text-rkube-color3-300 {
    color: var(--rkube-color3-300) !important;
}
.text-rkube-color3-400 {
    color: var(--rkube-color3-400) !important;
}
.text-rkube-color3-500 {
    color: var(--rkube-color3-500) !important;
}
.text-rkube-color3-600 {
    color: var(--rkube-color3-600) !important;
}
.text-rkube-color3-700 {
    color: var(--rkube-color3-700) !important;
}
.text-rkube-color3-800 {
    color: var(--rkube-color3-800) !important;
}
.text-rkube-color3-900 {
    color: var(--rkube-color3-900) !important;
}

.text-rkube-color4-50 {
    color: var(--rkube-color4-50) !important;
}
.text-rkube-color4-100 {
    color: var(--rkube-color4-100) !important;
}
.text-rkube-color4-200 {
    color: var(--rkube-color4-200) !important;
}
.text-rkube-color4-300 {
    color: var(--rkube-color4-300) !important;
}
.text-rkube-color4-400 {
    color: var(--rkube-color4-400) !important;
}
.text-rkube-color4-500 {
    color: var(--rkube-color4-500) !important;
}
.text-rkube-color4-600 {
    color: var(--rkube-color4-600) !important;
}
.text-rkube-color4-700 {
    color: var(--rkube-color4-700) !important;
}
.text-rkube-color4-800 {
    color: var(--rkube-color4-800) !important;
}
.text-rkube-color4-900 {
    color: var(--rkube-color4-900) !important;
}

.text-rkube-color5-50 {
    color: var(--rkube-color5-50) !important;
}
.text-rkube-color5-100 {
    color: var(--rkube-color5-100) !important;
}
.text-rkube-color5-200 {
    color: var(--rkube-color5-200) !important;
}
.text-rkube-color5-300 {
    color: var(--rkube-color5-300) !important;
}
.text-rkube-color5-400 {
    color: var(--rkube-color5-400) !important;
}
.text-rkube-color5-500 {
    color: var(--rkube-color5-500) !important;
}
.text-rkube-color5-600 {
    color: var(--rkube-color5-600) !important;
}
.text-rkube-color5-700 {
    color: var(--rkube-color5-700) !important;
}
.text-rkube-color5-800 {
    color: var(--rkube-color5-800) !important;
}
.text-rkube-color5-900 {
    color: var(--rkube-color5-900) !important;
}

body,
* {
    font-family: var(--cg-font-sans-serif);
}

.font-secondary {
    font-family: var(--cg-font-serif);
}

body.light-theme {
    /* background:#F3F3F3;     */
    background: var(--blue2-100);
    color: var(--text-color-secondary);
}

body.dark-theme {
    background: #101820;
    color: var(--text-color-secondary);
}

.p-menubar-start {
    display: flex;
    align-content: center;
    align-items: center;
}

object {
    height: 30px;
}

a {
    text-decoration: none;
    color: inherit;
}

.pi {
    z-index: 1;
}

.p-chip {
    border-radius: 50px;
}

.p-tooltip {
    position: absolute;
    margin-top: 10px;
}

body.blured {
    filter: blur(5px);
}

.p-datatable .p-datatable-tbody > tr > td {
    vertical-align: middle;
}

button.p-element.p-column-filter-add-button {
    border: 1px solid;
}

.p-card-content {
    position: relative;
}

.p-input-icon-left .p-password input {
    padding-left: 2.5rem;
}

.single-card-listing.disabled {
    filter: grayscale(1);
    opacity: 0.7;
    pointer-events: none;
}

.single-card-listing .p-card-content {
    padding: 0;
}

.iconModal {
    position: absolute;
    width: 7rem;
    height: 7rem;
    background: var(--primary-500);
    padding: 1rem;
    border-radius: 50%;
    top: -3rem;
    left: calc(50% - 3.5rem);
    border: 0.3rem solid var(--primary-200);
}

.iconModal img {
    filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(220deg) brightness(117%) contrast(112%);
}

.dialogIcon .p-dialog .p-dialog-header {
    padding-bottom: 0.6rem;
    background-color: var(--bluegray-50);
    align-items: flex-start;
}

.dialogIcon .p-dialog .p-dialog-header-icons {
    position: absolute;
    right: 1rem;
}

.single-card-listing.disabled > div *:not(.bgOpacity) {
    opacity: 0.7;
}

.dialogIcon .p-dialog .p-dialog-content {
    padding-bottom: 1rem;
    background-color: var(--bluegray-50);
}

.noBorderDialog .p-dialog .p-dialog-header,
.noBorderDialog .p-dialog .p-dialog-content {
    border-radius: 0;
}

.noBorderDialog .p-dialog .p-dialog-header {
    border-bottom: 1px solid var(--gray-200);
}

.noBorderDialog .p-tabview-panels {
    background: rgb(204 226 232 / 0.5);
    display: block;
    /* margin-top: 1.5rem; */
    border-radius: 0 !important;
    padding: 1.5rem !important;
    min-height: 30rem;
    /* max-height: 30rem; */
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    background: var(--primary-50);
    border-radius: 0;
}

.p-fileupload-content {
    text-align: center;
}

.p-fileupload .p-fileupload-buttonbar {
    display: flex;
}

.p-tooltip-auto.p-tooltip.p-tooltip-top {
    width: auto;
    white-space: nowrap;
    display: flex !important;
    margin-top: -5px;
}

.p-tooltip-auto.p-tooltip.p-tooltip-top * {
    white-space: nowrap;
    word-break: normal;
}

.p-tooltip-auto.p-tooltip-text {
    word-break: normal;
}

.tabViewExpansion bview .p-tabview-nav,
.tabViewExpansion .p-tabview .p-tabview-panels {
    background-color: var(--bluegray-50);
}

.tabViewExpansion .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    background: transparent !important;
}

.wrapper-app {
    min-height: 100vh;
    background: var(--blue2-100) url(/assets/images/subtle_dots.png);
    background-blend-mode: multiply;
    /* background-size: 2%; */
}

a#barsBtnMenu.opened {
    z-index: 9999;
}

.p-card {
    height: 100%;
}

.section-content .p-panel .p-panel-content {
    background: var(--bluegray-50);
}

.section-content .p-panel .p-panel-header {
    background: white;
    color: var(--primary-600);
    cursor: pointer;
}

.section-content-repeater .p-buttonset .p-button,
#dependacy-page .p-buttonset .p-button {
    width: 50%;
}

.dep-page .p-fieldset .p-fieldset-content {
    padding: 0;
}

.dep-page .p-fieldset .p-fieldset-legend {
    margin-left: 3rem;
}

.p-multiselect {
    min-width: 100%;
    max-width: 32rem;
    width: 100%;
}

.tabview-projects.p-tabview .p-tabview-panels {
    background: transparent;
    padding: 0;
}

.hover-primary-500:hover {
    color: var(--primary-500) !important;
}

.p-organizationchart-table {
    width: 100%;
}

.p-organizationchart .p-organizationchart-node-content.p-person {
    padding: 0;
    border: 0 none;
}

.p-organizationchart-node-content .p-node-toggler {
    bottom: -30px;
    border: 1px solid #0000002b;
}

#timelineActivities .p-card .p-card-content {
    padding-bottom: 0 !important;
}

.tabview-projects.p-tabview .tabViewExpansion .p-tabview-panels {
    padding: 2rem 3rem !important;
}

.sidebarJarFiles .p-sidebar-content::-webkit-scrollbar-thumb {
    background-color: var(--primary-400);
    border-radius: 0px;
}

#sidebarLocalStorage .p-sidebar-header,
#sidebarLocalStorage .p-sidebar-footer {
    display: none;
}

#sidebarLocalStorage .p-sidebar {
    background: transparent;
}

#sidebarLocalStorage .p-sidebar-content {
    padding: 1rem;
    background: #0000009e;
    color: white;
}

#sidebarLocalStorage .p-sidebar-bottom {
    height: auto;
}

.p-sidebar .p-sidebar-header {
    font-weight: bold;
}

.titleSection h2 {
    margin-top: 0;
}

.titleSection,
.titleSection p,
.top-header-settings,
.topBar-header .titleSection {
    margin-bottom: 0;
}

.topBar-header > .card {
    background: var(--surface-b);
    border-radius: 0;
}

.topBar-header {
    position: sticky;
    top: 80px;
    z-index: 4;
}

.p-toolbar {
    padding: 0;
    border: 0;
    background: transparent;
}

.topBar-header .p-fileupload-choose {
    border-radius: 2rem;
    background: var(--primary-50);
    color: var(--primary-900);
    padding: 0.75rem 1.25rem;
}

.topBar-header .p-button {
    font-size: 1rem;
    line-height: 1rem;
}

.titleSection h1 {
    font-family: var(--cg-font-sans-serif);
    font-weight: 800;
    font-size: 2.25rem;
    color: white;
    line-height: 2.6rem;
    margin-bottom: 0.5rem;
}

.titleSection p {
    font-size: 1rem;
    font-family: var(--cg-font-sans-serif);
    font-weight: 500;
    color: white;
    line-height: 1.8rem;
}

.titleSection {
    margin-bottom: 3.2rem;
    margin-top: 3.2rem;
}

.titleSection.first {
    margin-top: 0;
}

.titleSection h2 {
    font-size: 1.5rem;
    line-height: 1.7rem;
    margin-bottom: 0;
    color: white;
    font-weight: 700;
}

.titleSection i {
    font-size: 3rem;
    color: white;
}

.titleSection > span {
    display: flex;
    float: left;
    margin-right: 1.5rem;
}

.settingBreadcrumb a i {
    margin-right: 10px;
    display: block;
}

span.settingBreadcrumb a {
    display: flex;
    align-items: center;
    align-content: center;
}

span.settingBreadcrumb:after {
    content: ">";
    font-size: 2rem;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    margin-left: 1.5rem;
}

span.settingBreadcrumb a * {
    color: var(--bluegray-100);
}

span.settingBreadcrumb a:hover * {
    color: var(--secondary-color);
}

.header_popup_table h2 {
    margin: 0;
    font-family: var(--cg-font-sans-serif);
    color: var(--primary-900);
}

.header_popup_table p {
    font-family: var(--cg-font-sans-serif);
    color: var(--primary-800);
}

.p-toolbar.p-component > div {
    width: 50%;
}

.p-toolbar.p-component > div.p-toolbar-group-right {
    justify-content: end;
}

.emptyTable {
    font-size: 3rem;
    padding: 5rem 0;
    text-align: center;
    color: var(--bluegray-200);
}

.emptyTable i {
    font-size: 6rem;
}

/* .true-icon{
    color: var(--primary-500);
}

.false-icon{
    color: var(--purple-600);
} */

.colorRadios .p-radiobutton-box, .colorRadios .p-radiobutton{
    width: 3rem;
    height: 3rem;
}

.rkube-color1-500 .p-radiobutton-box,
.rkube-color1-500.p-radiobutton .p-radiobutton-box.p-highlight{
    background : var(--rkube-color1-500);
}

.rkube-color2-500 .p-radiobutton-box,
.rkube-color2-500.p-radiobutton .p-radiobutton-box.p-highlight{
    background : var(--rkube-color2-500);
}

.rkube-color3-500 .p-radiobutton-box,
.rkube-color3-500.p-radiobutton .p-radiobutton-box.p-highlight{
    background : var(--rkube-color3-500);
}

.rkube-color4-500 .p-radiobutton-box,
.rkube-color4-500.p-radiobutton .p-radiobutton-box.p-highlight{
    background : var(--rkube-color4-500);
}

.rkube-color5-500 .p-radiobutton-box,
.rkube-color5-500.p-radiobutton .p-radiobutton-box.p-highlight{
    background : var(--rkube-color5-500);
}

.p-avatarLetter{
    background: transparent;
}

.p-component-overlay {
    z-index: 9 !important;
}

.p-dialog > div {
    background: #f5f5f5 !important;
}

.p-avatar {
    font-size: inherit !important;
}

.tabview-projects.p-tabview .p-tabview-nav li {
    flex: 1;
    border-right: 1px solid var(--gray-200);
}

.tabview-projects.p-tabview .p-tabview-nav li .p-tabview-nav-link {
    justify-content: center;
}

.p-slidemenu .logout-link .p-menuitem-link * {
    color: var(--red-500) !important;
    opacity: 1 !important;
}

.notification-badge .p-badge {
    width: 0.9rem;
    height: 0.9rem;
    min-width: auto;
    margin-right: 1rem;
    margin-left: 0.5rem;
    background: var(--bluegray-300);
}

.notification-badge.unread .p-badge {
    background: var(--green-500);
}

.header-single-project .p-avatar-group p-avatar {
    margin-left: -0.6rem !important;
    font-size: 0.8rem;
}

.circle{
    border-radius: 50%;
    aspect-ratio: 1/1;
}

.panelSingleProject .p-panel-header {
    display: none;
}

.panelSingleProject .p-panel-content {
    background: transparent;
}

body .detailsTarget button.p-button {
    color: #ffffff !important;
    position: absolute;
    right: 0;
    opacity: 0.7;
}

body .detailsTarget button.p-button:hover {
    opacity: 1;
}

.card-summary:hover > * {
    background: var(--primary-50);
}

.card-summary > * {
    transition: 0.5s all;
}

/* p-accordiontab.p-element.bg-primary-100 a.p-accordion-header-link {
    background-color: var(--gray-100) !important;
} */

.w-full .p-accordion-tab {
    width: 100%;
}

.table-issues .p-datatable-tbody > tr > td {
    word-break: break-word;
}

.revisions-timeline .p-timeline-event-opposite {
    display: none;
}

.revisions-timeline .p-timeline-event-separator {
    /* display: block !important;
    width: 8rem;
    flex: inherit; 
    min-width: 10rem;
    */
    flex: inherit;
}

.revisions-timeline .p-timeline-event-content {
    padding-bottom: 3rem !important;
}

.revisions-timeline .p-card-content {
    padding: 0 !important;
}

p-divider.bg-primary-500 .p-divider.p-divider-horizontal:before {
    background: var(--primary-500) !important;
}

.revisions-timeline app-team-assignes > span {
    margin: 0 !important;
}

.bg-primary-500.p-panelmenu .p-panelmenu-header > a {
    background: var(--primary-500);
    color: white;
    border: none;
    padding: 1.25rem 0;
}


.bg-primary-500.p-panelmenu  *{
    background: transparent !important;
    border: none;
    color: white;
}

.bg-primary-500.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-icon,
.bg-primary-500.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-text{
    color: white;
}

.bg-primary-500.p-panelmenu .p-panelmenu-header > a:hover,.bg-primary-500.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover *, .bg-primary-500.p-panelmenu .p-panelmenu-header:not(.p-highlight):not(.p-disabled) > a:hover{
    background: transparent !important;
    color: var(--primary-100) !important;
}

.shadow-0{
    box-shadow: none;
}

p-sidebar.p-element.detailsTemplateNewKit .p-sidebar-header button {
    display:none;
}

p-sidebar.p-element.detailsTemplateNewKit .p-sidebar .p-sidebar-header {
    padding: 0;
}

p-sidebar.p-element.detailsTemplateNewKit .p-sidebar .p-sidebar-content {
    padding-top: 20px;
}

p-sidebar.p-element.detailsTemplateNewKit .p-card-header img {
    border-radius: 10px !important;
    overflow: hidden;
    display: flex;
}

/* 
.content-area .p-card-header {
    padding: 1rem 1rem 0.5rem 1rem;
} */

.content-area .p-card-title {
    color: var(--primary-500);
}

.content-area .p-card-subtitle {
    color: var(--primary-300);
}

.content-area .p-card-content {
    color: var(--surface-600);
}


.p-steps-item[aria-selected=true] a .p-steps-number {
    background: var(--primary-500) !important;
    color: white !important;
    font-weight: 600;
}

.p-steps-item[aria-selected=true] a .p-steps-title {
    color: var(--primary-500) !important;
    font-weight: 600;
}

/* .p-tabview-panel {
    background: var(--bluegray-50);
    padding: 3rem;
} */

body .p-panel .p-panel-content{
    border: 0;
}
/* 
.single-card-listing:hover .p-card {
    border: 0.5rem solid var(--primary-500);
    border-radius: 0.8rem;
    transition: 0.5s all;
} */


/* .single-card-listing .p-card {
    border: 0.5rem solid transparent;
} */

.gradient-border .single-card-listing .p-card {
    --borderWidth: 0.3rem;
    position: relative;
    border-radius: var(--borderWidth);
    border-color:transparent;
}

.gradient-border .single-card-listing:hover .p-card:after {
    content: '';
    position: absolute;
    top: calc(-1 * var(--borderWidth));
    left: calc(-1 * var(--borderWidth));
    height: calc(100% + var(--borderWidth) * 2);
    width: calc(100% + var(--borderWidth) * 2);
    background: linear-gradient(60deg, #50f37e, #1fd9cb, #2cacf3, #924dfe, #cd29fa);
    border-radius: 0.4rem;
    z-index: -1;
    animation: animatedgradient 3s ease alternate infinite;
    background-size: 300% 300%;
}

@keyframes animatedgradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.p-password input {
    width: 100%;
}

app-files-icons {
    display: flex;
    width: 50px;
    height: 50px;
}

ul.steps-refactor .p-button.p-button-sm .p-button-icon {
    font-size: 0.55rem;
}

.badgeAppsTable {
    width: 15px;
    height: 15px;
    min-width: 15px;
    max-width: 15px;
    padding: 0rem;
}

.badge-Informational, .badge-Info{
    background : #edf1f8;
    box-shadow : 0 0 2px rgba(0, 0, 0, 0.3);
}

.badge-Low{
    background : #ffc919;
    box-shadow : 0 0 2px rgba(0, 0, 0, 0.3);
}

.badge-Medium{
    background : #E89E0C;
    box-shadow : 0 0 2px rgba(0, 0, 0, 0.3);
}

.badge-High{
    background : #e25055;
    box-shadow : 0 0 2px rgba(0, 0, 0, 0.3);
}

.badge-Critical{
    background : #af3d41;
    box-shadow : 0 0 2px rgba(0, 0, 0, 0.3);
}


.badge-Queued{
    background : #edf1f8;
    box-shadow : 0 0 2px rgba(0, 0, 0, 0.3);
}

.badge-Running{
    background : #facf34;
    box-shadow : 0 0 2px rgba(0, 0, 0, 0.3);
}

.badge-Completed{
    background : #50bb9b;
    box-shadow : 0 0 2px rgba(0, 0, 0, 0.3);
}

.badge-Failed{
    background : #e25055;
    box-shadow : 0 0 2px rgba(0, 0, 0, 0.3);
}

.header-app .p-menubar-start {
    flex: 1;
}

.single-card-listing:hover img {
    filter: brightness(0) saturate(100%) invert(96%) sepia(11%) saturate(0%) hue-rotate(326deg) brightness(118%) contrast(100%) !important;
}

.single-card-listing img, .single-card-listing .p-card.p-component, .single-card-listing .p-card.p-component * {
    transition: 0.25s all;
}

.single-card-listing:hover .p-card.p-component {
    background: var(--primary-300);
    color: white;
}

.single-card-listing:hover .p-card.p-component * {
    color: white !important;
}

.btn-git .p-button-label {
    font-weight: 600;
}

.full-panel .p-panel-content {
    padding: 0 !important;
}

.full-panel .p-tabview-panels {
    padding: 2rem 1.25rem !important;
}

.overlayPanelRefactor {
    max-width: 500px;
    line-height: 1.2rem;
}

.timeline-refactor > div {
    max-width: 25rem;
    height: 2rem;
    margin: 0;
}

.timeline-refactor .p-timeline-event-opposite, .timeline-refactor .p-timeline-event-content {
    padding: 0 !important;
    display: none;
}

.no-overflow .p-datatable-wrapper{
    overflow:inherit !important
}

.timeline-details-app-refactor .timeline-refactor>div {
    max-width: 2rem;
    height: 100%;
    margin: 0;
}

.timeline-details-app-refactor .timeline-refactor>div>div {
    height: calc(100% / 5);
}

.timeline-details-app-refactor .timeline-refactor>div>div:last-child {
    height: auto;
}

img.imgIcon {
    filter: brightness(0) saturate(100%) invert(23%) sepia(88%) saturate(2336%) hue-rotate(172deg) brightness(88%) contrast(103%);
}

.overlayPanelRefactor.appDetail {
    max-width: 350px;
    line-height: 1.2rem;
}

.overflow-hidden .p-dialog-content {
    overflow: hidden;
}

.revisionTable .p-paginator {
    background: var(--primary-50);
    color: var(--primary-500);
    border: 1px solid var(--primary-100);
    border-top: 0;
}

.revisionTable .p-paginator * {
    border-color: var(--primary-100);
    color: var(--primary-500);
}
/* 
.summary-java-card .p-card-body {
    padding: 0 !important;
} */

.summary-java-card .p-card-content {
    padding: 0 !important;
}

.summary-java-timeline .p-timeline-event-opposite {
    padding-top: 0 !important;
    flex: none;
}

.summary-java-timeline .p-timeline-event-separator {
    position: relative;
    width:100%;
}

.summary-java-timeline .p-timeline-event-content {
    padding: 0 !important;
    flex: auto;
}

.summary-java-timeline > div:last-child .custom-separator {
    display: none;
}

.summary-java-timeline .p-timeline-event-content p {
    padding-right: 0rem;
}

.summary-java-card .p-card .p-card-body {
    padding: 2.5rem;
}

.delete-item * {
    color: var(--red-500)!important;
    font-weight: 500;
}

/* html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; } */

/* .cdk-overlay-connected-position-bounding-box {
    background: rgb(33 33 33 / 75%);
} */

div.mat-mdc-menu-panel.bdc-walk-popup .title .header, div.mat-menu-panel.bdc-walk-popup .title .header, div.mat-mdc-menu-panel.bdc-walk-popup .buttons button, div.mat-menu-panel.bdc-walk-popup .buttons button {
    color: var(--primary-500);
}

.cdk-overlay-container {
    background: rgb(33 33 33 / 50%);
}

.index-overlay-walk {
    z-index: 9999;
    position: relative;
    transition: 1s all;
    pointer-events: none;
}

.index-overlay-walk * {
    pointer-events: none;
}

.p-steps .p-steps-item.p-highlight .p-steps-title{
    line-height: 1.4rem;
    color: var(--primary-500);
}

.p-steps .p-steps-item.p-highlight .p-steps-number {
    background: var(--primary-500);
    color: white;
}

#progressBlankProject .p-progressbar-label {
    display: none !important;
}

.p-password-mask.ng-invalid.ng-touched input {
    border-color: #e24c4c;
}

.table-summary-java .p-timeline-event-content,
.table-summary-java .p-timeline-event-opposite {
    display:none;
}

.table-summary-java .p-timeline-event{
    min-height: auto;
}

.p-component-overlay {
    background-color: rgb(0 0 0 / 65%);
}

.report-single-modal > div {
    background: whitesmoke !important;
}

.report-single-modal > div.p-dialog-content {
    padding-bottom: 0rem !important;
    padding-left: 0;
    padding-right: 0;
}

.report-single-modal > div.p-dialog-content > div {
    padding: 0 !important;
}

.p-fileupload-row > div:first-child {
    display: none;
}

.p-fileupload-row > div {
    text-align: left;
}

.p-fileupload-row > div:last-child {
    text-align: right;
}

#sidebarHelp .p-sidebar {
    background: var(--blue2-50);
}

.p-sidebar .p-sidebar-header {
    border-bottom: 1px solid #00708a24;
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item {
    border-bottom: 1px solid var(--bluegray-100);
}

.p-autocomplete-panel {
    max-height: 35vh !important;
}

.p-tooltip {
    font-size: 0.85rem;
}

.p-autocomplete-panel .p-autocomplete-items {
    padding: 0;
}

.fieldsetCompact .p-fieldset .p-fieldset-legend {
    margin-left: 1.1rem;
    padding: 0.75rem .5rem;
}

.p-tooltip {
    max-width: none;
}

.chevron-primary.p-dropdown .p-dropdown-trigger {
    color: #ffffff;
    background: var(--primary-500);
    border: 1px solid var(--primary-500);
    padding: 0.75rem 1.25rem;
    font-size: 1rem;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 0px;
}

.chevron-primary.p-dropdown .p-dropdown-trigger:hover {
    background: var(--primary-700);
    color: #ffffff;
    border-color: var(--primary-700);
}

.chevron-primary.p-dropdown > .p-icon-wrapper svg {
    display: block;
    margin-right: 15px;
    color: var(--primary-700);
}

.p-input-icon-left > i:first-of-type {
    left: 0.9rem;
}

body span.p-input-icon-left i,
body span.p-input-icon-right i {
    /* top: 29px; */
    top: 2.1rem;
}

code[class*="language-"]{
  position: relative;
  padding-top: 30px !important;
}

code[class*="language-"]::before {
    content: attr(data-language);
    position: absolute;
    top: 0;
    left: 0;
    padding: 5px 15px;
    color: #fff;
    opacity: 0.3;
}

.response code:not([class*="language-"]) {
    background-color: var(--primary-50);
    padding: 1px 4px;
    border-radius: 5px;
    border: 1px solid var(--primary-100);
    margin: 0 2px;
    color: var(--primary-800);
    font-size: 0.85rem;
}

.hljs-copy-container {
    top: 20px;
    right: 10px;
}

button.hljs-copy-button {
    min-width: 30px;
    cursor: pointer;
}